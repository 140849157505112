
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class ProgressBar extends Vue {
    
    @Prop() progress: number;
    @Prop() color: string;
    
    @Prop({
        required: false,
        default: false,
        type: Boolean
    }) animate: boolean;

    localCompleted: number = 0;

    @Watch('progress')
    onCompletedChange(next, prev){
        this.localCompleted = next;
    }
    created(){
        if(this.animate){
            this.localCompleted = 0;
            setTimeout(() => {
                this.localCompleted = this.progress || 0;
            }, 0)
        } else {
            this.localCompleted = this.progress || 0;
        }
    }
}
