import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/img/close.svg'


const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "fs-14 greyBlue"
}
const _hoisted_4 = {
  key: 1,
  class: "fs-14 greyBlue"
}
const _hoisted_5 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", {
    class: "bottomModalContainer",
    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close')))
  }, [
    _createVNode("div", {
      class: "bottomModal",
      onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent","stop"]))
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          (_ctx.post.title)
            ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.post.title), 1))
            : (_openBlock(), _createBlock("span", _hoisted_4, [
                _createVNode(_component_localized_text, {
                  localizedKey: "app_postdi(nome)",
                  text: "Post di",
                  class: "fs-14 greyBlue"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.post.creator.name), 1)
              ]))
        ]),
        _createVNode("img", {
          src: _imports_0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        })
      ]),
      _createVNode("div", _hoisted_5, [
        (_ctx.canContent || _ctx.post.creator.identifier == _ctx.$store.state.user.identifier)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "genericButton bgGreyBlue",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openEditModal()))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "app_modifica",
                text: "Modifica",
                class: "fs_13 white"
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.post.creator.identifier != _ctx.$store.state.user.identifier)
          ? (_openBlock(), _createBlock("div", {
              key: 1,
              class: "genericButton bgGreyBlue",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reportPost()))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "app_segnala",
                text: "Segnala",
                class: "fs_13 white"
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.canContent)
          ? (_openBlock(), _createBlock("div", {
              key: 2,
              class: "genericButton bgWhite mt-2",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.elimina()))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "app_elimina",
                text: "Elimina",
                class: "fs_13"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}