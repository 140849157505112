
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { makeFilePreview } from '@/utils';
import ProgressBar from '@/components/progressBar.vue';
import UoseWriter from '@/components/uoseWriter.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { CategoryAttachmentClient, PostClient } from '@/services/Services';

@Options({
    components: {
        ProgressBar,
        UoseWriter
    }
})
export default class EditPostModal extends Vue {

    @Prop() postVm: OM.PostVm;
    @Prop() callback: () => void;

    vm: OM.CreatePostVm = new OM.CreatePostVm();
    uploading: boolean = false;
    uploadProgress: number = 0;

    //gestione categorie degli allegati
    categoriesAllegati: VM.CategoryAttachmentVM[] = []; 
    categorySelectedByUser: string = "";
    ableCategories: boolean = false; 

    //variabili per la gestione dei file e degli attachment sia per post che per allegati 
    imgOrAllegato: File = null;
    wantDeleteAttachment: boolean = false;
    
    created() {
        //inizializzo vm da postvm che mi arriva , per poter chiamare createOrUpdatePost (cambia il tipo)
        this.vm = {
            identifier: this.postVm.identifier,
            containerIdentifier: this.postVm.parentIdentifier,
            isAllegato: this.postVm.isAllegato,
            publishedDate: this.postVm.publishedDate,
            text: this.postVm.text,
            title: this.postVm.title,
            categoryAttachment : null, //FIXME
            postType: null, //FIXME
        };

        this.categorySelectedByUser = this.postVm.category;
        if(!this.postVm.category){
            this.ableCategories = false;
        }else{
            this.ableCategories = true;
        }
        
        //se ho una preview la mostro
        if(this.postVm.preview){
            this.preview = this.postVm.preview;
        }
    
        CategoryAttachmentClient.getCategoryAllAttachmentByCommunity(this.postVm.parentIdentifier)
        .then(x => {
            this.categoriesAllegati = x;
        })
    }

    doPost() {
        delete this.vm.publishedDate;
        store.state.globalSpinner++;
        if(this.wantDeleteAttachment){
            PostClient.deletePostImage(this.vm.identifier)
            .then(x => {
                this.createOrUpdatePost()
            })
        } else {
            this.createOrUpdatePost()
        }
    }
    
    createOrUpdatePost() {
        PostClient.createOrUpdatePost(this.vm)
        .then( x => {
            if(!this.wantDeleteAttachment){
                PostClient.updatePostImage(this.vm.identifier , this.imgOrAllegato)
                .then(x => {
                    this.$emit('close');
                })
                .finally(() => {
                    store.state.globalSpinner--;
                    this.uploading = false;
                });
                this.callback();
            } else {
                this.$emit('close');
                store.state.globalSpinner--;
                this.uploading = false;
            }
            
        })
    }

    toggleRestoreImg(){
        if(this.wantDeleteAttachment)
        this.wantDeleteAttachment = false;
        else
        this.wantDeleteAttachment = true;
    }

    preview: any = null;
    @Watch('imgOrAllegato')
    onAttachmentChange(next, prev){
        this.makePreview();
    }

    makePreview() {
        if(this.imgOrAllegato){
            if(typeof this.imgOrAllegato == "string"){
                this.preview = this.imgOrAllegato;
            } else {
                makeFilePreview(this.imgOrAllegato)
                .then(x => {
                    this.preview = x;
                })
            }
        }
    }

    setCategory(event, categoryId) {
        event.preventDefault()
        this.categorySelectedByUser = categoryId;
        if (this.ableCategories == false) {
            this.vm.categoryAttachment.id = "";
        } else {
            this.vm.categoryAttachment = this.categoriesAllegati.find(cat => cat.id === categoryId);
        }
    }
}

