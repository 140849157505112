
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getUniversalISOStringDate, makeFilePreview } from '@/utils';
import { CommunityClient, PostClient } from '@/services/Services';
import ProgressBar from '@/components/progressBar.vue';
import UoseWriter from '@/components/uoseWriter.vue';
import Removable from '@/components/removable.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Options({
    components: {
        ProgressBar,
        UoseWriter,
        Removable
    }
})
export default class NewForumPostModal extends Vue {

    @Prop() title: string;
    @Prop() containerIdentifier: string;
    @Prop() postType: string;

    @Prop({
        default: () => []
    }) tags: OM.ForumTag[];

    @Prop({
        default: () => new VM.CreateForumPostVm()
    }) vm: VM.CreateForumPostVm;

    @Prop({
        default: ""
    }) attachmentUrl: string;

    localVm: VM.CreateForumPostVm = new VM.CreateForumPostVm();
    isCordova: boolean = !!window.cordova;
    uploading: boolean = false;
    newTag: string = "";
    uploadProgress: number = 0;
    todayDate: string = getUniversalISOStringDate(new Date());

    created() {
        this.vm.containerIdentifier = this.containerIdentifier;
        this.vm.postType = this.postType;

        this.localVm = {...this.vm};

        if(this.attachmentUrl)
            this.preview = this.attachmentUrl;
    }
    doPost() {
        delete this.localVm.publishedDate;
        store.state.globalSpinner++;
        PostClient.createForumPost(this.localVm)
        .then(x => {
            PostClient.updatePostImage(x, this.vm.attachment)
            .then(x => {
                this.$emit('close');
            })
            .finally(() => {
                store.state.globalSpinner--;
                this.uploading = false;
            });
        })
    }

    doRec: boolean = false;
    recbuffer: string = "";
    communities: OM.SearchResultVm[] = [];
    @Watch('localVm.text')
    onTextChange(next, prev) {
        if(next.length - prev.length == 1){
            let lastLetter = next[next.length - 1];
            if(lastLetter == ' '){
                this.doRec = false;
                this.recbuffer = "";
            } else {
                if(this.doRec){
                    this.recbuffer += lastLetter;
                    this.searchCommunities(this.recbuffer);
                } else {
                    if(lastLetter == '&'){
                        this.doRec = true;
                    }
                }
            }
        } else if(next.length - prev.length == -1) {
            this.recbuffer = this.recbuffer.substr(0, this.recbuffer.length - 1);
            this.searchCommunities(this.recbuffer);
        }
    }
    chatSearchCommunities: OM.SearchResultVm[] = [];
    searchCommunities(query) {
        CommunityClient.chatSearch(query)
        .then(x => {
            this.chatSearchCommunities = x;
        })
    }
    setCommunity(item: OM.SearchResultVm) {
        this.communities
    }

    preview: any = null;
    @Watch('localVm.attachment')
    onAttachmentChange(next, prev) {
        this.makePreview();
    }
    makePreview() {
        if(this.localVm.attachment){
            if(typeof this.localVm.attachment == "string"){
                this.preview = this.localVm.attachment;
            } else {
                makeFilePreview(this.localVm.attachment)
                .then(x => {
                    this.preview = x;
                })
            }
        }
    }

    removeAttachment() {
        this.preview = "";
        this.localVm.attachment = null;
    }

}

