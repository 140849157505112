
import { Options, Vue } from 'vue-class-component';
import { PostClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import { selfUrl } from '../config';
import CommentsModal from '@/components/commentsModal.vue';
// import PostDetailModal from '@/components/postDetailModal.vue';
import PostOptionsModal from '@/components/postOptionsModal.vue';
import ShareButton from '@/components/shareButton.vue';
import store from '@/store';
import * as OM from '@/model';
import * as CONST from '@/const';
import router from '@/router';

@Options({
    components: {
        ShareButton
    }
})
export default class Post extends Vue {

    @Prop() post: OM.PostVm;
    @Prop() isBlock: boolean;
    @Prop() canContent: boolean;
    @Prop() communityIdentifier: string;
    @Prop() onlyView: boolean = false;

    @Prop({
        default: true
    }) showActions: boolean;  

    @Prop({
        default: false
    }) showCommunityTitle: boolean;  

    youtubeLink: string = "";
    shareUrl: string = "";
    loggedUser: OM.AppUserVm = store.state.user;

    created() {
        var url = this.$route.path.substr(1);
        this.shareUrl = selfUrl + url + "?f=" + this.post.identifier;
    }

    postText: any;
    mounted() {
        if(this.post.text && this.post.text.indexOf("<a href='https://www.youtube.com/") > -1)
            this.selectVideoFromUrl();

        if(this.post.text && this.post.text.indexOf("<a href='https://youtu.be/") > -1)
            this.selectVideoFromShare();

        this.postText = this.$refs.postText;
        let height = parseInt(getComputedStyle(this.postText).height);
        if(height > 160){
            this.postText.classList.add('limited');
        }
    }

    showAll() {
        this.postText.classList.remove('limited');
    }

    get openExternalAttachment(){
        return (this.post.attachmentUrl && window.cordova) || !window.cordova;
    }

    doLike() {
        PostClient.like(this.post.identifier, !this.post.liked, false)
        .then(x => {
            this.post.liked = !this.post.liked;
            if(this.post.liked)
                this.post.likes.push(store.state.user.identifier);
            else
                this.post.likes = this.post.likes.filter(x => x != store.state.user.identifier);
        });
    }

    openPerson(item: OM.NameIdentifier) {
        if(item.identifier == store.state.user.identifier)
            return;
        
        for(var i = 0; i < this.$opModal.modals().length; i++) {
            this.$opModal.closeLast();
        }

        this.$router.push('/profile/' + item.identifier);
    }

    reloadPost() {
        this.$emit("postreloaded", this.post.identifier);

        PostClient.getPostVmById(this.post.identifier)
        .then(x => {
            if(!x){
                this.$emit('removed', this.post.identifier);
            } else {
                this.post.identifier = x.identifier;
                this.post.publishedDate = x.publishedDate;
                this.post.text = x.text;
                this.post.creator = x.creator;
                this.post.attachmentUrl = x.attachmentUrl;
                this.post.preview = x.preview;
                this.post.mimeType = x.mimeType;
                this.post.likes = x.likes;
                this.post.commentNumber = x.commentNumber;
                this.post.liked = x.liked;

                this.$emit("postedited", this.post);
            }
        })
    }

    openComments() {
        var parentCommentType;
        if(this.$route.name.toString().indexOf('community_info') > -1)
            parentCommentType = CONST.NotificationType.AllegatoComment;
        else if(this.$route.name.toString().indexOf('community') > -1)
            parentCommentType = CONST.NotificationType.CommunityPostComment;
        else if(this.$route.name.toString().indexOf('event') > -1)
            parentCommentType = CONST.NotificationType.EventPostComment;
        else if(this.$route.name.toString().indexOf('fiera') > -1)
            parentCommentType = CONST.NotificationType.FieraPostComment;

        this.$opModal.show(CommentsModal, {
            parentIdentifier: this.post.identifier,
            //grandParentIdentifier: this.post.identifier,
            containerIdentifier: this.communityIdentifier,
            commentType: parentCommentType
        }, () => {
            this.reloadPost();
        });
    }

    // openPostDetail() {
    //     this.$opModal.show(PostDetailModal, {
    //         post: this.post,
    //         communityIdentifier: store.state.community.identifier
    //     })
    // }

    openPostOptionsModal() {
        this.$opModal.show(PostOptionsModal, {
            post: this.post,
            canContent: this.canContent
        }, () => {
            this.reloadPost();
        })
    }

    selectVideoFromUrl() {
        var beginVideo = this.post.text.indexOf("<a href='https://www.youtube.com/");
        var tempText = this.post.text.slice(beginVideo);
        var endVideo = tempText.indexOf("</a>") + 4 + beginVideo;
        
        var textToRemove = this.post.text.slice(beginVideo, endVideo);
        this.post.text = this.post.text.replace(textToRemove, "");

        var link = textToRemove.slice(textToRemove.indexOf("href='") + 6);
        var linkWithParams = link.slice(0, link.indexOf("'")).replace("watch?v=", "embed/");

        if(linkWithParams.indexOf("&") > -1)
            this.youtubeLink = linkWithParams.slice(0, linkWithParams.indexOf("&"));
        else
            this.youtubeLink = linkWithParams;
    }

    selectVideoFromShare(){
        var beginVideo = this.post.text.indexOf("<a href='https://youtu.be/");
        var tempText = this.post.text.slice(beginVideo);
        var endVideo = tempText.indexOf("</a>") + 4 + beginVideo;
        
        var textToRemove = this.post.text.slice(beginVideo, endVideo);
        this.post.text = this.post.text.replace(textToRemove, "");

        var link = textToRemove.slice(textToRemove.indexOf("href='") + 6);
        var linkWithParams = link.slice(0, link.indexOf("'")).replace("watch?v=", "embed/");

        if(linkWithParams.indexOf("&") > -1)
            this.youtubeLink = linkWithParams.slice(0, linkWithParams.indexOf("&"));
        else
            this.youtubeLink = linkWithParams;

        var lastIndexSlash = this.youtubeLink.lastIndexOf("/");
        var videoCode = this.youtubeLink.substr(lastIndexSlash + 1);

        this.youtubeLink = "https://www.youtube.com/embed/" + videoCode;
    }

    openAttachment(){
        if(window.cordova) {
            // if(this.post.mimeType.indexOf('openxml') > -1 ||
            //     this.post.mimeType.indexOf('pdf') > -1 ||
            //     this.post.mimeType.indexOf('xls') > -1)
            //     window.open(encodeURI('https://docs.google.com/gview?embedded=true&url=' + this.post.attachmentUrl), '_system', 'location=yes,EnableViewPortScale=yes');
            // else
            
                window.cordova.InAppBrowser.open(this.post.attachmentUrl, '_system');
            }
        else
            window.open(this.post.attachmentUrl, '_blank');

        // var ref = window.open(encodeURI(this.post.attachmentUrl), '_blank', 'location=yes');
        // if(window.cordova)
        //     window.cordova.InAppBrowser.open(this.post.attachmentUrl, '_blank');
        // else
        //     window.open(this.post.attachmentUrl, '_blank');
        // window.open(this.post.attachmentUrl, "_system" , "location=yes, enableViewportScale=yes, hidden=no");
        
    }

    openPostInCommunity() {
        if(!this.showCommunityTitle)
            return;

        router.push("/communities/" + this.post.communitySlug + "/Bacheca" + "?f=" + this.post.identifier)
    }

}

