
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PostClient, ForumTagClient, ReportClient } from '@/services/Services';
import EditPostModal from '@/components/editPostModal.vue';
import NewForumPostModal from '@/views/community/views/forum/modals/newForumPostModal.vue';
import ReportCustomConfirm from './reportCustomConfirm.vue';
import ModalSuccessError from './modalSuccessError.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import * as CONST from '@/const';
import { LocalizationServices } from '@/services/LocalizationServices';
import ConfirmModal from '@/components/confirmModal.vue';

@Options({})
export default class PostOptionsModal extends Vue {

    @Prop() post: OM.PostVm;
    @Prop() canContent: boolean;

    openEditModal() {
        if (!this.post.tag || !this.post.tag.tag) {
            this.$opModal.show(EditPostModal, {
                postVm: this.post
            }, () => this.reloadPost())
        }
        else {
            var tags = [];
            var vm = new VM.CreateForumPostVm();
            ForumTagClient.getPublishedTag(this.post.parentIdentifier)
            .then( x => {
                tags = x.map( x => {
                    return x.tag
                });
                vm.identifier = this.post.identifier;
                vm.text = this.post.text;
                vm.tag = this.post.tag;
                
                var text = LocalizationServices.getLocalizedValue("app_modificapost", "Modifica post");

                this.$opModal.show(NewForumPostModal, {
                    containerIdentifier: this.post.parentIdentifier,
                    postType: CONST.NotificationType.CommunityPostNew,
                    attachmentUrl: this.post.attachmentUrl,
                    tags: tags,
                    vm: vm,
                    title: text
                }, () => this.reloadPost())
            })
        }
    }
    
    reloadPost() {
        PostClient.getPostVmById(this.post.identifier)
        .then(x => {
            this.post.identifier = x.identifier;
            this.post.publishedDate = x.publishedDate;
            this.post.text = x.text;
            this.post.creator = x.creator;
            this.post.attachmentUrl = x.attachmentUrl;
            this.post.preview = x.preview;
            this.post.mimeType = x.mimeType;
            this.post.likes = x.likes;
            this.post.commentNumber = x.commentNumber;
            this.post.liked = x.liked;

            this.$emit("close");
        })
    }

    elimina() {
        var messageKey = "seisicurodivolereliminarequestopostdefinitivamente";
        var messageValue = "Sei sicuro di vole eliminare questo post definitivamente?"
        var messageText = LocalizationServices.getLocalizedValue(messageKey, messageValue, "", "");
        this.$opModal.show(ConfirmModal, {
            text: messageText,
            okCb:() =>{
                PostClient.deletePost(this.post.identifier)
                .then(x => {
                    this.$emit('close');
                })
                this.$opModal.closeLast();

            },
        })
        
    }

    reportPost() {
        this.$opModal.show(ReportCustomConfirm, {
            callback: (value: string) => {
                var createReport = new OM.CreateReport();
                createReport.reportType = VM.ReportType.Post;
                createReport.note = value;
                createReport.itemIdentifier = this.post.identifier;
                ReportClient.createReport(createReport)
                .then( x => {
                    for(var i = 0; i < this.$opModal.modals().length; i++) {
                        this.$opModal.closeLast();
                    }

                    var text = LocalizationServices.getLocalizedValue("app_postsegnalatoconsuccesso", "Post segnalato con successo!");

                    this.$opModal.show(ModalSuccessError, {
                        message: text,
                    })
                })
            }
        })
    }

}

